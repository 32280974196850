import { Observable } from 'rxjs';

/**
 * Abstract app config object. Provides information about current application environment configuration.
 */
export abstract class AppConfig {
	// This must NOT have any environment-provided properties declarations, since environments are application-specific.
	// Please use application-specific implementations for this (e.g. web-app-config.service.ts)!

	/** API base URL. */
	public abstract readonly apiUrl: string;

	/** App version. */
	public abstract readonly version$: Observable<string>;

	/** Origin url. */
	public abstract readonly originUrl: string;

	/** Custom URL scheme. */
	public abstract readonly customSchemeUrl: string;

	/** API key for vonage service. */
	public abstract readonly vonageApiKey: string;

	/** API key for stripe service. */
	public abstract readonly stripeApiKey: string;
}
